export const ROUTES = {
  ADMIN: '/admin',
  ACCOUNT: '/account', // Deprecated
  ACCOUNT_EVENTS: '/account/events',
  ACCOUNT_HELP: '/account/help',
  ACCOUNT_ORDERS: '/account/orders',
  ACCOUNT_USER: '/account/user',
  EVENT: '/event',
  LOGIN: '/login',
  REGISTER: '/register',
  ACCOUNT_CONNECTORS: '/account/connectors',
  EVENT_CREATE: '/event/new',
  EVENT_ORDER: '/account/events/order',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  HELP_FAQ: '/help-faq',
  ABOUT: '/about',
  FORGOT_PASSWORD: '/reset-password',
  PRICES: '/prices',
  HOW_IT_WORKS: '/how-it-works',
}
