<template>
  <div class="contact-options">
    <h2 class="light-primary-title contact-options__title">
      {{ $t('need_more_help') }}
    </h2>
    <p>{{ $t('contact_us') }}</p>
    <div class="contact-options__blocks">
      <app-card
        class="contact-options__block"
        icon-name="ic:outline-email"
        :title="$t('email_us')"
        :link="emailContactLink"
      />
      <app-card
        class="contact-options__block"
        icon-name="ic:baseline-whatsapp"
        :title="$t('whatsapp_us')"
        :link="whatsAppContactLink"
      />
    </div>
  </div>
</template>

<script setup>
  import { EMAIL_CONTACT_LINK, WHATSAPP_CONTACT_LINK } from '~/lib/constants/contact-options'

  const whatsAppContactLink = WHATSAPP_CONTACT_LINK
  const emailContactLink = EMAIL_CONTACT_LINK
</script>

<style lang="scss">
  .contact-options {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .contact-options__title {
    text-align: center;
  }

  .contact-options__blocks {
    display: flex;
    gap: var(--spacing-medium);
    justify-content: center;
    margin-top: var(--spacing-medium);
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  .contact-options__block.app-card {
    min-width: 300px;
  }
</style>
