import { defineStore } from 'pinia'

import actions from './actions'
import getters from './getters'

export const useMainStore = defineStore('main', {
  state: () => ({
    isDesktop: false,
    isMobile: false,
    isHoverSupported: false,
    isPortrait: false,
    isLandscape: false,

    isMobileDevice: false,
  }),
  actions,
  getters,
})
