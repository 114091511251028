import { useOverlayStore } from '~/stores/overlay'

/**
 * @typedef {OverlayService}
 * @alias this.$overlayService
 */
export class OverlayService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp

    this.overlayStore = useOverlayStore()
  }

  init() {
    this.$scrollLockService = this.nuxtApp.$scrollLockService
  }

  /**
   * @param {Object} payload
   * @param {Object} [payload.settings]
   * @param {string} [payload.settings.type]
   * @param {string} [payload.settings.component]
   * @param {Object} [payload.settings.options]
   * @param {Object} [payload.desktop]
   * @param {string} [payload.desktop.type]
   * @param {string} [payload.desktop.component]
   * @param {Object} [payload.desktop.options]
   * @param {Object} [payload.mobile]
   * @param {string} [payload.mobile.type]
   * @param {string} [payload.mobile.component]
   * @param {Object} [payload.mobile.options]
   * @returns {void}
   */
  setCurrentOverlay(payload) {
    this.overlayStore.setCurrentOverlay(payload)
  }

  /**
   * @param {Object} payload
   * @returns {void}
   */
  setCurrentOverlayOptions(payload) {
    this.overlayStore.setCurrentOverlayOptions(payload)
  }

  /**
   * @returns {void}
   */
  closeAppModal() {
    this.overlayStore.closeAppModal()

    this.#enableScroll()
  }

  /**
   * @returns {Promise<void>}
   */
  closeAppTray() {
    this.overlayStore.closeAppTray()

    this.#enableScroll()
  }

  /**
   * @returns {Promise<void>}
   */
  closeFlyIn() {
    this.overlayStore.closeFlyIn()

    this.#enableScroll()
  }

  /**
   * Only enable scroll if there is not another
   * AppModal/AppTray/FlyIn active.
   * @returns {void}
   */
  #enableScroll() {
    if (!this.overlayStore.overlayIsActive) {
      this.$scrollLockService.enableScroll()
    }
  }
}
