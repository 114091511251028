import { useUserStore } from '~/stores/user'
import { ROUTES } from '~/lib/constants/routes'

export default defineNuxtRouteMiddleware((to) => {
  if (process.server) {
    return
  }

  const nuxt = useNuxtApp()

  const localePath = nuxt.$localePath
  const userStore = useUserStore()

  if (!userStore.isAuthenticated) {
    return handleUnauthenticatedFlow()
  }

  if (!userStore.isAdmin) {
    return handleUserFlow()
  }

  function handleUnauthenticatedFlow() {
    if (to.name?.startsWith('account') || to.name?.startsWith('admin')) {
      abortNavigation()

      return navigateTo(localePath(ROUTES.LOGIN))
    }
  }

  function handleUserFlow() {
    if (to.name?.startsWith('admin')) {
      abortNavigation()

      return navigateTo(localePath(ROUTES.ACCOUNT_EVENTS))
    }
  }
})
