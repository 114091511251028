export default {
  /**
   * @param {string} eventId
   * @param {string} token
   * @returns {void}
   */
  setToken(eventId, token) {
    this.tokens[eventId] = token
  },
}
