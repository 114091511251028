export default {
  /**
   * @param {momentshare.models.user.User|null} user
   * @returns {void}
   */
  setUser(user) {
    this.user = user
  },

  /**
   * @param {boolean} isInitialized
   * @returns {void}
   */
  setIsInitialized(isInitialized) {
    this.isInitialized = isInitialized
  },

  /**
   * @returns {void}
   */
  logout() {
    this.setUser(null)
  },

  acceptedCookies() {
    this.acceptedCookie = true
  },

  /**
   * @param {Object} token
   * @returns {void}
   */
  setToken(token) {
    this.token = token
  },
}
