import * as Sentry from '@sentry/vue'
import ignoredErrors from '~/lib/constants/ignored-errors'

export class SentryService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.config = nuxtApp.$config.public
    this.pluginsAreInitialized = false
  }

  init() {
    const sentryEnabled = this.config.sentryEnabled
    const sentryConfig = this.config.sentry

    if (!sentryEnabled || !sentryConfig.dsn) {
      return
    }

    this.sentry = Sentry.init({
      app: this.nuxtApp.vueApp,
      dsn: sentryConfig.dsn,
      environment: sentryConfig.environment,
      integrations: [
        Sentry.browserTracingIntegration({ router: this.nuxtApp.router }),
      ],
      ignoreErrors: ignoredErrors,

      tracesSampleRate: sentryConfig.tracesSampleRate, // Change in prod
      tracePropagationTargets: ['localhost', 'https://momentshare.io'],

      replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
      replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
    })
  }

  async initPlugins(event) {
    event.currentTarget.removeEventListener(event.type, window.ms_initSentryPlugins) // remove the event listener that got triggered

    if (this.pluginsAreInitialized) {
      return
    }

    this.pluginsAreInitialized = true

    await import('@sentry/vue')
      .then((lazyLoadedSentry) => {
        Sentry.addIntegration(lazyLoadedSentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: ['https://momentshare.io', 'https://hertzner-api.momentshare.io/api/v1', 'https://content.dropboxapi.com', 'https://www.googleapis.com'],
          networkRequestHeaders: ['Event-Authorization', 'Authorization'],
        }))
      })
  }

  capture(error) {
    if (!this.sentry) return

    this.sentry.captureException(error)
  }
}
