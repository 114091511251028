import Cookies from 'js-cookie'

/**
 * @typedef {ClientCookieService}
 * @alias this.$clientCookieService
 */
export class ClientCookieService {
  /**
   * @param {string} key
   * @return {string}
   */
  get (key) {
    return Cookies.get(key)
  }

  /**
   * @param {string} key
   * @param {string} value
   * @param {Object} options
   * @return {string}
   */
  set (key, value, options = {}) {
    return Cookies.set(key, value, options)
  }

  /**
   * @param {string} key
   * @return {undefined}
   */
  delete (key) {
    return Cookies.remove(key)
  }
}
