import axios from 'axios'
import { USER_AUTH_COOKIE_KEY } from '../constants/cookies'
import { mapUser } from '~/lib/mappers/user-mapper'
import { useUserStore } from '~/stores/user'

/**
 * @typedef {ApiService}
 * @alias this.$apiService
 */
export class ApiService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.config = nuxtApp.$config.public
    this.route = useRoute()
    this.userStore = useUserStore()

    this.instance = axios.create({
      baseURL: this.config.apiUrl,
      headers: {
        apiKey: this.config.apiKey,
      },
    })

    this.authInstance = axios.create({
      baseURL: this.config.apiUrl,
      headers: {
        apiKey: this.config.apiKey,
      },
    })

    this.setTokenInterceptor(this.instance)
    this.setErrorInterceptor(this.instance)

    this.setErrorInterceptor(this.authInstance)
  }

  init() {
    this.$clientCookieService = this.nuxtApp.$clientCookieService
    this.$sentryService = this.nuxtApp.$sentryService
  }


  /**
   * @param {Object} tokenDetails
   * @returns {void}
   */
  setToken(tokenDetails) {
    this.setTokenToStore(tokenDetails)
    this.setTokenInCookies(tokenDetails)
  }

  setTokenInCookies(tokenDetails) {
    this.$clientCookieService.set(USER_AUTH_COOKIE_KEY, JSON.stringify(tokenDetails), { expires: 180 })
  }

  setTokenToStore(tokenDetails) {
    this.userStore.setToken(tokenDetails)
  }

  /**
   * @returns {Promise<String>}
   */
  async getToken() {
    let tokenDetails = this.userStore.token

    if (!tokenDetails) {
      //check from cookies
      tokenDetails = await this.$clientCookieService.get(USER_AUTH_COOKIE_KEY)
      tokenDetails = tokenDetails ? JSON.parse(tokenDetails) : null
    }

    if (!tokenDetails) {
      return Promise.resolve(null)
    }

    this.setTokenToStore(tokenDetails)

    if (tokenDetails.expirationDate > new Date().getTime()) {
      return tokenDetails.token
    }

    // Token is expired. Try to refresh. Return null if unsuccessful to force the 401 from api.
    return this.refreshToken(tokenDetails)
  }

  /**
   * @param {Object} tokenDetails
   * @returns {Promise<string>}
   */
  async refreshToken(tokenDetails) {
    return this.authInstance.post('/auth/refresh-token', {
      refreshToken: tokenDetails.refreshToken,
    }).then(response => {
      this.setToken(response.data)

      return response.data.token
    }).catch(() => {
      return null
    })
  }

  /**
   * @param {string} username
   * @param {string} password
   * @returns {Promise<void>}
   */
  login(username, password) {
    return this.authInstance
      .post('/auth/login', { userName: username, password })
      .then(response => {
        this.setToken(response.data)
      })
  }

  /**
   * @param {string} username
   * @param {string} password
   * @param {string} displayName
   * @returns {Promise<void>}
   */
  register({ username, password, displayName }) {
    return this.instance
      .post('/auth/register', { userName: username, password, displayName })
      .then(response => {
        this.setToken(response.data)
      })
  }

  /**
   * @returns {Promise<momentshare.models.user.User>}
   */
  getUser() {
    return this.instance
      .get('/user')
      .then(response => mapUser(response.data))
  }

  /**
   * @param {Object} axiosInstance
   * @returns {Promise}
   */
  setTokenInterceptor(axiosInstance) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        // We also can get an error here from the response interceptor
        if (!config) {
          return Promise.reject()
        }

        try {

          const token = await this.getToken()

          if (!token) {
            return config
          }

          config.headers = {
            ...config.headers,
            Authorization: token,
          }

          return config
        } catch (error) {
          return Promise.reject(error)
        }
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error)
      }
    )
  }

  /**
   * @param {Object} axiosInstance
   * @returns {Promise}
   */
  setErrorInterceptor(axiosInstance) {
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const t = this.nuxtApp.$i18n.t

        if (error.response?.data?.message) {
          return Promise.reject(t(`api_errors.${error.response?.data?.message}`))
        }

        return Promise.reject(error)
      }
    )
  }
}
