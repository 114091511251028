import { ConnectedDriveData } from '~/lib/models/connected-drive-data'

export function mapGoogleConnectedDriveData(data) {
  return new ConnectedDriveData({
    userEmail: data.user?.emailAddress,
    totalSpace: bytesToGigabyte(data.storageQuota?.limit),
    usedSpace: bytesToGigabyte(data.storageQuota?.usage),
  })
}

export function mapDropboxConnectedDriveData(data) {
  return new ConnectedDriveData({
    userEmail: data.email,
    totalSpace: bytesToGigabyte(data.allocation?.allocated),
    usedSpace: bytesToGigabyte(data.used),
  })
}

function bytesToGigabyte(value) {
  return (value / (1024 * 1024 * 1024)).toFixed(2)
}
