import { clearAllBodyScrollLocks, disableBodyScroll } from '~/lib/vendor/body-scroll-lock'

/**
 * @typedef {ScrollLockService}
 * @alias this.$scrollLockService
 */
export class ScrollLockService {
  /**
   * @param {HTMLElement} element
   * @param {Object} [options]
   * @returns {void}
   */
  disableScroll({ element, options = {} }) {
    disableBodyScroll(element, options)
  }

  /**
   * @returns {void}
   */
  enableScroll() {
    clearAllBodyScrollLocks()
  }
}
