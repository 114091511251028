/**
 * @memberOf momentshare.models.admin
 * @constructor
 */
export class EventWithMetaData {
  /**
   * @param {momentshare.models.event.Event} event
   * @param {string} username
   * @param {number} sizeInBytes
   * @param {number} sizeInMB
   * @param {number} sizeInGB
   */
  constructor({
    event,
    username,
    sizeInBytes,
    sizeInMB,
    sizeInGB,
  }) {
    this.event = event
    this.sizeInBytes = sizeInBytes
    this.sizeInMB = sizeInMB
    this.sizeInGB = sizeInGB
    this.username = username
  }
}
