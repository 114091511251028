import { defineStore } from 'pinia'

import actions from './actions'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    user: null,
    isInitialized: false,
    acceptedCookies: false,
    token: null,
  }),
  actions,
  getters: {
    /**
     * @param state
     * @returns {boolean}
     */
    isAuthenticated(state) {
      return Boolean(state.user)
    },

    /**
     * @param state
     * @returns {boolean}
     */
    isAdmin(state) {
      return state.user?.isAdmin ?? false
    },
  },
})
