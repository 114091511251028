/**
 * @memberOf momentshare.models.payment
 * @constructor
 */
export class DiscountCode {
  constructor({
    id,
    discountCode,
    percentage,
    usesLeft,
    validTill,
    userEmail,
    userEmailRegex,
  }) {
    this.id = id
    this.discountCode = discountCode
    this.percentage = percentage
    this.usesLeft = usesLeft
    this.validTill = validTill
    this.userEmail = userEmail
    this.userEmailRegex = userEmailRegex
  }
}
