export default {
  reset() {
    this.selectedEventId = null
    this.events = []
  },

  /**
   * @param {momentshare.models.event.Event[]} [events]
   * @returns {void}
   */
  setEvents(events) {
    this.events = events ?? []
  },

  /**
   * @param {string} id
   * @returns {void}
   */
  setSelectedEventId(id) {
    this.selectedEventId = id
  },
}
