import { ClientCookieService } from '~/lib/services/client-cookie-service'
import { ApiService } from '~/lib/services/api-service'
import { ImagesService } from '~/lib/services/images-service'
import { EventService } from '~/lib/services/event-service'
import { PaymentService } from '~/lib/services/payment-service'
import { OrderService } from '~/lib/services/order-service'
import { UserService } from '~/lib/services/user-service'
import { OverlayService } from '~/lib/services/overlay-service'
import { ScrollLockService } from '~/lib/services/scroll-lock-service'
import { GoogleService } from '~/lib/services/google-service'
import { CurrencyService } from '~/lib/services/currency-service'
import { DateTimeService } from '~/lib/services/date-time-service'
import { ThemeService } from '~/lib/services/theme-service'
import { DropboxService } from '~/lib/services/dropbox-service'
import { AdminService } from '~/lib/services/admin-service'
import { AnalyticsService } from '~/lib/services/analytics-service'
import { InteractionsService } from '~/lib/services/interactions-service'
import { BrowserHelperService } from '~/lib/services/browser-helper-service'
import { SentryService } from '~/lib/services/sentry-service'
import { NetworkConnectionService } from '~/lib/services/network-connection-service'

export default defineNuxtPlugin(async (nuxtApp) => {
  const services = {
    clientCookieService: new ClientCookieService(),
    apiService: new ApiService(nuxtApp),
    imagesService: new ImagesService(nuxtApp),
    eventService: new EventService(nuxtApp),
    paymentService: new PaymentService(nuxtApp),
    orderService: new OrderService(nuxtApp),
    userService: new UserService(nuxtApp),
    scrollLockService: new ScrollLockService(),
    overlayService: new OverlayService(nuxtApp),
    googleService: new GoogleService(nuxtApp),
    currencyService: new CurrencyService(nuxtApp.$i18n.locale.value),
    dateTimeService: new DateTimeService(nuxtApp.$i18n.locale.value),
    themeService: new ThemeService(nuxtApp),
    dropboxService: new DropboxService(nuxtApp),
    adminService: new AdminService(nuxtApp),
    analyticsService: new AnalyticsService(nuxtApp),
    interactionsService: new InteractionsService(nuxtApp),
    browserHelperService: new BrowserHelperService(),
    sentryService: new SentryService(nuxtApp),
    networkConnectionService: new NetworkConnectionService(nuxtApp),
  }

  const serviceKeys = Object.keys(services)

  // Provide services into the Nuxt context
  serviceKeys.forEach((key) => {
    nuxtApp.provide(key, services[key])
  })

  // Run init in each service
  for (let i = 0; i < serviceKeys.length; i++) {
    const key = serviceKeys[i]
    await services[key].init?.()
  }
})
