/**
 * @memberOf momentshare.models.event
 * @constructor
 */
export class Event {
  /**
   * @param {string} id
   * @param {string} name
   * @param {string} status
   * @param {Date} date
   * @param {string} shortLink
   * @param {Date} [publicationDate]
   * @param {Date} [publicationExpiresAt]
   * @param {string} safeName
   * @param {momentshare.models.event.EventProperty[]} [properties]
   */
  constructor({
    id,
    name,
    status,
    date,
    publicationDate,
    publicationExpiresAt,
    safeName,
    properties,
    shortLink,
  }) {
    this.id = id
    this.name = name
    this.status = status
    this.safeName = safeName
    this.date = date
    this.shortLink = shortLink
    if (publicationDate) this.publicationDate = publicationDate
    if (publicationExpiresAt) this.publicationExpiresAt = publicationExpiresAt
    if (properties) this.properties = properties
  }
}

export const EVENT_STATES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ENDED: 'ended',
  DISABLED: 'disabled',
}
