import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'

/**
 * @typedef {ThemeService}
 * @alias this.$themeService
 */
export class ThemeService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
  }

  init() {
    this.$t = this.nuxtApp.$i18n.t
  }

  /**
   * @return {Array}
   */
  getThemes() {
    return [
      {
        name: this.$t('theme_translations.party.theme_name'),
        image: '/themes/party/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Merriweather Sans',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.party.image_upload_text'),
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#000000',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD]: '/themes/party/upload-background.png',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/party/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
        },
      },
      {
        name: this.$t('theme_translations.wedding.theme_name'),
        image: '/themes/wedding/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Dancing Script',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.wedding.image_upload_text'),
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#4D1C00',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD]: '/themes/wedding/upload-background.png',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/wedding/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
        },
      },
      {
        name: this.$t('theme_translations.sport.theme_name'),
        image: '/themes/sport/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Bebas Neue',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.sport.image_upload_text'),
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#000000',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD]: '/themes/sport/upload-background.png',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/sport/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
        },
      },
      {
        name: this.$t('theme_translations.company.theme_name'),
        image: '/themes/company/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Bree Serif',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.company.image_upload_text'),
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#783200',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD]: '/themes/company/upload-background.png',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/company/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#fff',
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
        },
      },
      {
        name: this.$t('theme_translations.festival.theme_name'),
        image: '/themes/festival/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Open Sans',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.festival.image_upload_text'),
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD]: '/themes/festival/upload-background.png',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/festival/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#101010',
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
        },
      },
      {
        name: this.$t('theme_translations.birthday.theme_name'),
        image: '/themes/birthday/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Roboto',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.birthday.image_upload_text'),
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#BD00FF',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD]: '/themes/birthday/upload-background.png',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/birthday/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
        },
      },
      {
        name: this.$t('theme_translations.trip.theme_name'),
        image: '/themes/trip/card-image.jpg',
        properties: {
          [EVENT_PROPERTY_KEY.FONT]: 'Prompt',
          [EVENT_PROPERTY_KEY.UPLOAD_TEXT]: this.$t('theme_translations.trip.image_upload_text'),
          [EVENT_PROPERTY_KEY.BACKGROUND_COLOR]: '#ffffff',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE_UPLOAD]: '/themes/trip/upload-background.png',
          [EVENT_PROPERTY_KEY.BACKGROUND_IMAGE]: '/themes/trip/background.jpg',
          [EVENT_PROPERTY_KEY.TEXT_COLOR]: '#000000',
          [EVENT_PROPERTY_KEY.ENABLE_GALLERY]: true,
        },
      },
    ]
  }
}
