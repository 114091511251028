/**
 * @memberOf momentshare.models.event
 * @constructor
 */
export class EventProperty {
  constructor({
    id,
    key,
    value,
    validUntil,
  }) {
    this.id = id
    this.key = key
    this.value = value
    this.validUntil = validUntil
  }
}
