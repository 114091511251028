export default {
  /**
   * @param {string} activeAppModal
   * @returns {void}
   */
  setActiveAppModal(activeAppModal) {
    this.$state.activeAppModal = activeAppModal
  },

  /**
   * @param {string} [activeAppModalTransition]
   * @returns {void}
   */
  setActiveAppModalTransition(activeAppModalTransition = 'fade') {
    this.$state.activeAppModalTransition = activeAppModalTransition
  },

  /**
   * @param {Object} activeAppModalOptions
   * @returns {void}
   */
  setActiveAppModalOptions(activeAppModalOptions) {
    this.$state.activeAppModalOptions = activeAppModalOptions
  },

  /**
   * @param {string} activeAppTray
   * @returns {void}
   */
  setActiveAppTray(activeAppTray) {
    this.$state.activeAppTray = activeAppTray
  },

  /**
   * @param {string} [activeAppTrayTransition]
   * @returns {void}
   */
  setActiveAppTrayTransition(activeAppTrayTransition = 'app-tray-transition') {
    this.$state.activeAppTrayTransition = activeAppTrayTransition
  },

  /**
   * @param {Object} activeAppTrayOptions
   * @returns {void}
   */
  setActiveAppTrayOptions(activeAppTrayOptions) {
    this.$state.activeAppTrayOptions = activeAppTrayOptions
  },

  /**
   * @param {string} activeFlyIn
   * @returns {void}
   */
  setActiveFlyIn(activeFlyIn) {
    this.$state.activeFlyIn = activeFlyIn
  },

  /**
   * @param {string} [activeFlyInTransition]
   * @returns {void}
   */
  setActiveFlyInTransition(activeFlyInTransition = 'fly-in-transition') {
    this.$state.activeFlyInTransition = activeFlyInTransition
  },

  /**
   * @param {Object} activeFlyInOptions
   * @returns {void}
   */
  setActiveFlyInOptions(activeFlyInOptions) {
    this.$state.activeFlyInOptions = activeFlyInOptions
  },

  /**
   * @param {Object} [desktop]
   * @param {Object} [mobile]
   * @param {Object} [settings]
   * @returns {void}
   */
  setCurrentOverlay({ desktop, mobile, settings }) {
    this.$state.desktop = desktop
    this.$state.mobile = mobile
    this.$state.settings = settings
  },

  /**
   * @param {Object} options
   * @returns {void}
   */
  setCurrentOverlayOptions(options) {
    this.$state.settings.options = options
  },

  /**
   * @returns {void}
   */
  closeAppModal() {
    this.$state.activeAppModal = null
    this.$state.activeAppModalOptions = null
    this.$state.desktop = null
    this.$state.mobile = null
    this.$state.settings = null

    setTimeout(() => {
      this.$state.activeAppModalTransition = null
    }, 500)
  },

  /**
   * @returns {void}
   */
  closeAppTray() {
    this.$state.activeAppTray = null
    this.$state.activeAppTrayOptions = null
    this.$state.desktop = null
    this.$state.mobile = null
    this.$state.settings = null

    setTimeout(() => {
      this.$state.activeAppTrayTransition = null
    }, 500)
  },

  /**
   * @returns {void}
   */
  closeFlyIn() {
    this.$state.activeFlyIn = null
    this.$state.activeFlyInOptions = null
    this.$state.desktop = null
    this.$state.mobile = null
    this.$state.settings = null

    setTimeout(() => {
      this.$state.activeFlyTransition = null
    }, 500)
  },

  setEventImagesModalIsActive(bool) {
    this.$state.eventImagesModalIsActive = bool
  },
}
