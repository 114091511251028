export default defineNuxtPlugin(async (nuxtApp) => {
  const GTM = nuxtApp.$config.public.gtm
  const { initialize } = useGtag()

  if (document.readyState === 'complete') {
    setTimeout(() => initGTM(GTM), 5000)
  } else {
    window.addEventListener('load', function () {
      setTimeout(() => initGTM(GTM), 5000)
    })
  }

  window.ms_gTagInitialize = initialize
  window.ms_initGTMOnEvent = (event) => initGTMOnEvent(event, GTM)

  document.addEventListener('scroll', window.ms_initGTMOnEvent)
  document.addEventListener('mousemove', window.ms_initGTMOnEvent)
  document.addEventListener('touchstart', window.ms_initGTMOnEvent)
})

function initGTMOnEvent(event, GTM) {
  initGTM(GTM)
  event.currentTarget.removeEventListener(event.type, window.ms_initGTMOnEvent) // remove the event listener that got triggered
}

function initGTM(GTM) {
  if (window.ms_gtmDidInit) {
    return false
  }

  window.ms_gTagInitialize()
  window.ms_gtmDidInit = true // flag to ensure script does not get added to DOM more than once.

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true

  // ensure PageViews is always tracked (on script load)
  script.onload = () => {
    dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 })
  }
  script.src = `https://www.googletagmanager.com/gtm.js?id=${GTM}`
  document.head.appendChild(script)
}
