import revive_payload_client_4sVQNw7RlN from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/local/src/party-pictures-fe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/local/src/party-pictures-fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/local/src/party-pictures-fe/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/usr/local/src/party-pictures-fe/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/usr/local/src/party-pictures-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/usr/local/src/party-pictures-fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_initialize_services_P3fIrVdva7 from "/usr/local/src/party-pictures-fe/plugins/01.initialize-services.js";
import _02_initialize_gtm_client_ZsxDllpZfH from "/usr/local/src/party-pictures-fe/plugins/02.initialize-gtm.client.js";
import _02_initialize_user_client_aV6et3QxRT from "/usr/local/src/party-pictures-fe/plugins/02.initialize-user.client.js";
import _03_init_match_media_client_oZeUdGcTgp from "/usr/local/src/party-pictures-fe/plugins/03.init-match-media.client.js";
import _05_initialize_sentry_plugins_client_aQEmB53NtO from "/usr/local/src/party-pictures-fe/plugins/05.initialize-sentry-plugins.client.js";
import error_handler_client_oSUvZRlTYg from "/usr/local/src/party-pictures-fe/plugins/error-handler.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _01_initialize_services_P3fIrVdva7,
  _02_initialize_gtm_client_ZsxDllpZfH,
  _02_initialize_user_client_aV6et3QxRT,
  _03_init_match_media_client_oZeUdGcTgp,
  _05_initialize_sentry_plugins_client_aQEmB53NtO,
  error_handler_client_oSUvZRlTYg
]