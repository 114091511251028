<template>
  <component
    :is="wrapperComponent"
    :to="link"
    class="app-card"
  >
    <div class="app-card__details">
      <icon
        v-if="iconName"
        class="app-card__icon"
        :name="iconName"
      />
      <h2 class="app-card__title">
        {{ title }}
      </h2>
      <slot />
    </div>
  </component>
</template>

<script setup>
  import NuxtLink from '#app/components/nuxt-link'

  const props = defineProps({
    iconName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: null,
    },
  })

  const wrapperComponent = props.link ? NuxtLink : 'div'
</script>

<style lang="scss">
.app-card {
  border-radius: var(--default-border-radius);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-small);
  background: var(--color-lightest);
  box-shadow: 0 19px 26px rgba(0, 0, 0, 0.15);
}

.app-card__icon {
  font-size: 1.8rem;
  margin-bottom: 8px;
  color: var(--color-secondary)
}

.app-card__title {
  font-size: 1rem;
  text-align: center;
}

.app-card__details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100px;
  flex-direction: column;
}
</style>
