import { SubscriptionOption } from '../models/subscription-option'

export function mapSubscriptionOptions(subscriptionOptions) {
  return subscriptionOptions.map(subscriptionOption => mapSubscriptionOption(subscriptionOption))
}

export function mapSubscriptionOption(subscriptionOption) {
  return new SubscriptionOption({
    id: subscriptionOption.id,
    cost: subscriptionOption.cost,
    description: subscriptionOption.description,
    tax: subscriptionOption.tax,
    taxPercentage: subscriptionOption.taxPercentage,
  })
}
