/**
 * @memberOf momentshare.models.admin
 * @constructor
 */
export class EventsTotalSize {
  /**
   * @param {number} totalUsageMB
   * @param {number} totalUsageGB
   */
  constructor({
    totalUsageMB,
    totalUsageGB,
  }) {
    this.totalUsageMB = totalUsageMB
    this.totalUsageGB = totalUsageGB
  }
}
