import { EventWithMetaData } from '~/lib/models/admin/event-with-meta-data'
import { mapEvent } from '~/lib/mappers/event-mapper'
import { EventsTotalSize } from '~/lib/models/admin/events-total-size'

export function mapEventsWithMetaData(eventsWithMetaData, filesPath) {
  return eventsWithMetaData.map(eventWithMetaData => mapEventWithMetaData(eventWithMetaData, filesPath))
}

export function mapEventWithMetaData(eventWithMetaData, filesPath) {
  return new EventWithMetaData({
    event: mapEvent(eventWithMetaData.event, filesPath),
    username: eventWithMetaData.event.username,
    sizeInBytes: eventWithMetaData.sizeInBytes,
    sizeInMB: eventWithMetaData.sizeInMB,
    sizeInGB: eventWithMetaData.sizeInGB,
  })
}

export function mapEventsTotalSize(eventsTotalSize) {
  return new EventsTotalSize({
    totalUsageMB: eventsTotalSize.totalUsageMB,
    totalUsageGB: eventsTotalSize.totalUsageGB,
  })
}
