import { ROUTES } from '~/lib/constants/routes'

export default defineNuxtRouteMiddleware((to) => {
  if (process.server) {
    return
  }

  const nuxt = useNuxtApp()
  const localePath = nuxt.$localePath

  if (to.path === localePath(ROUTES.ACCOUNT)) {
    return navigateTo(localePath(ROUTES.ACCOUNT_EVENTS))
  }
})
