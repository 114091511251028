import { DiscountCode } from '../models/discount-code'

export function mapDiscountCode(discountCode) {
  return new DiscountCode({
    id: discountCode.id,
    discountCode: discountCode.discountCode,
    percentage: discountCode.percentage,
    usesLeft: discountCode.usesLeft,
    validTill: discountCode.validTill,
    userEmailRegex: discountCode.userEmailRegex,
  })
}
