import { Image } from '~/lib/models/image'

export function mapImages (images, filesPath) {
  return images.map(image => mapImage(image, filesPath))
}

export function mapGoogleImages (images) {
  return images.map(image => mapGoogleImage(image))
}

export function mapDropboxImages (images) {
  return images.map(image => mapDropboxImage(image))
}

export function mapImage (image, filesPath) {
  return new Image({
    id: image.id,
    eventId: image.eventId,
    thumbFilePath: `${filesPath}/${image.thumbFilePath}`,
    filePath: `${filesPath}/${image.filePath}`,
    originalFilePath: `${filesPath}/${image.originalFilePath}`,
    mimeType: image.mimeType,
    likes: image.likes,
    orderIndex: image.orderIndex,
  })
}

export function mapGoogleImage (image) {
  return new Image({
    id: image.id,
    eventId: image.eventId,
    thumbFilePath: mapGoogleThumbnailLink(image.thumbnailLink),
    filePath: mapGoogleImageLink(image.thumbnailLink),
    originalFilePath: image.webContentLink,
    mimeType: image.mimeType,
    likes: image.likes,
  })
}

export function mapDropboxImage (image) {
  return new Image({
    id: image.id,
    eventId: image.eventId,
    thumbFilePath: `data:image/jpeg;base64,${image.thumbnailLink}`,
    filePath: image.directLink,
    originalFilePath: image.directLink,
    mimeType: mapDropboxMimeType(image.path_display),
    likes: image.likes,
  })
}

function mapGoogleThumbnailLink(link) {
  return link?.replace('s220', 's600')
}

function mapGoogleImageLink(link) {
  return link?.replace('s220', 's4000')
}

function mapDropboxMimeType(path) {
  const parts = path.split('.')
  const extension = parts[parts.length - 1].toLowerCase()
  const videoExtension = ['mp4', 'mov', 'mpeg', 'm4v']

  if (videoExtension.includes(extension)) return `video/${extension}`

  return `image/${extension}`
}
