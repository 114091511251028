import { Base } from '~/lib/models/base/base'

/**
 * @memberOf momentshare.models.user
 * @constructor
 */
export class User extends Base {
  /**
   * @param {number} id
   * @param {string} username
   * @param {string} displayName
   * @param {boolean} googleDriveConnected
   * @param {boolean} dropboxConnected
   * @param {momentshare.models.user.UserAddress} [address]
   * @param {boolean} [isAdmin]
   */
  constructor({
    id,
    username,
    displayName,
    googleDriveConnected,
    dropboxConnected,
    address,
    isAdmin,
  }) {
    super()

    /** @type {number} */
    this.id = id

    /** @type {string} */
    this.username = username

    /** @type {string} */
    this.displayName = displayName

    /** @type {boolean} */
    this.googleDriveConnected = googleDriveConnected

    /** @type {boolean} */
    this.dropboxConnected = dropboxConnected

    /** @type {momentshare.models.user.UserAddress|null} */
    this.address = address

    /** @type {boolean} */
    this.isAdmin = isAdmin ?? false
  }

}
