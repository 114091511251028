import { mapSubscriptionOptions, mapSubscriptionOption } from "~/lib/mappers/subscription-mapper"
import { mapDiscountCode } from "~/lib/mappers/discount-code-mapper"

/**
 * @typedef {PaymentService}
 * @alias this.$paymentService
 */
export class PaymentService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
  }

  /**
   * @returns {Promise<momentshare.models.subscription.SubscriptionOption[]>} Options for subscription
   */
  getSubscriptionOptions() {
    return this.$apiService.instance.get('/payment/subscription-options')
      .then(response => mapSubscriptionOptions(response.data))
  }

  /**
   * @param {number} id
   * @returns {Promise<momentshare.models.subscription.SubscriptionOption>} Option for subscription
   */
  getSubscriptionOption(id) {
    return this.$apiService.instance.get(`/payment/subscription-option/${id}`)
      .then(response => mapSubscriptionOption(response.data))
  }

  /**
   * @param {string} discountCode
   * @returns {Promise<momentshare.models.payment.DiscountCode>}
   */
  checkDiscountCode(discountCode) {
    return this.$apiService.instance.post(`/payment/check-discount-code`, { discountCode })
      .then(response => mapDiscountCode(response.data))
  }
}
