export const APP_MODAL = 'AppModal'
export const APP_TRAY = 'AppTray'
export const FLY_IN = 'FlyIn'

// Modals
export const QR_CODE_MODAL = 'QrCodeModal'
export const DELETE_IMAGES_MODAL = 'DeleteImagesModal'
export const UPLOAD_IMAGES_MODAL = 'UploadImagesModal'
export const EVENT_DELETE_MODAL = 'EventDeleteModal'
export const EVENT_UPGRADE_MODAL = 'EventUpgradeModal'
export const REMOVE_CONNECTOR_MODAL = 'RemoveConnectorModal'
export const ADMIN_DISCOUNT_CODE_MODAL = 'AdminDiscountCodeModal'
export const RESET_CUSTOM_EVENT_SORTING_CONFIRMATION_MODAL = 'ResetCustomEventSortingConfirmationModal'
export const LOGIN_MODAL = 'LoginModal'

// Fly in's
export const GENERAL_NAVIGATION_FLY_IN = 'GeneralNavigationFlyIn'

// Trays
export const SORT_MEDIA_CONTROLS_TRAY = 'SortMediaControlsTray'
