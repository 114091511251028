<template>
  <button
    class="app-button"
    :class="{
      'app-button--secondary': props.secondary,
      'app-button--small': props.small,
      'app-button--smaller': props.smaller,
      'app-button--clean': props.clean,
      'app-button--light': props.light,
      'app-button--bold': props.bold,
      'app-button--error': props.error,
      'app-button--disabled': props.disabled,
    }"
    :type="type"
  >
    <app-spinner
      v-if="loading"
      dimension="20px"
      :color="spinnerColor"
    />
    <slot v-else />
  </button>
</template>

<script setup>
  const props = defineProps({
    secondary: {
      default: false,
      type: Boolean,
    },
    light: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    smaller: {
      default: false,
      type: Boolean,
    },
    clean: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  })

  const spinnerColor = computed(() => {
    if (props.secondary) {
      return 'black'
    }

    return 'white'
  })
</script>

<style lang="scss">
  .app-button {
    @include appButton();
  }

  .app-button:disabled {
    cursor: not-allowed;
  }

  // Light
  .app-button--light {
    --button-text-color: var(--color-primary);

    background: var(--color-secondary);
    border: 1px solid var(--color-secondary);
  }

  // Secondary
  .app-button--secondary {
    --button-text-color: var(--color-primary);

    background: transparent;
    border: 1px solid var(--color-primary);
    transition: border .2s ease-in-out;
  }

  // Secondary
  .app-button--secondary:hover {
    border: 1px solid var(--color-primary-50);
  }

  // Secondary && Light
  .app-button--secondary.app-button--light {
    --button-text-color: var(--color-secondary);

    background: transparent;
    border: 1px solid var(--color-secondary);
  }

  .app-button--clean {
    --button-text-color: var(--color-primary);
  }

  .app-button--small {
    padding: 8px 12px;
  }

  .app-button--smaller {
    padding: 6px 10px;
    font-size: vaR(--font-size-small)
  }

  .app-button--bold {
    font-weight: 500;
  }

  .app-button--error {
    color: white;
    background: var(--color-error);
    border: 1px solid var(--color-error);
  }

  .app-button--secondary.app-button--error {
    background: transparent;
    color: var(--color-error);
    border: 1px solid var(--color-error);
  }

  .app-button--secondary.app-button--error:hover {
    border: 1px solid var(--color-error);
  }

  .app-button--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
