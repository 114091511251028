import { storeToRefs } from 'pinia'

import { useMainStore } from '~/stores/main'

export default defineNuxtPlugin(() => {
  if (!process.client) {
    return
  }

  const mainStore = useMainStore()

  // Media Query Lists
  const desktopMatchMedia = window.matchMedia('(min-width: 1024px)')
  const mobileMatchMedia = window.matchMedia('(max-width: 1023px)')
  const hoverMatchMedia = window.matchMedia('(hover: hover)')
  const portraitMatchMedia = window.matchMedia('(orientation: portrait)')
  const landscapeMatchMedia = window.matchMedia('(orientation: landscape)')

  const {
    isMobile,
    isDesktop,
    isHoverSupported,
    isPortrait,
    isLandscape,
  } = storeToRefs(mainStore)

  desktopMatchMedia.addListener(onDesktopMatchMedia)
  mobileMatchMedia.addListener(onMobileMatchMedia)
  hoverMatchMedia.addListener(onHoverMatchMedia)
  portraitMatchMedia.addListener(onPortraitMatchMedia)
  landscapeMatchMedia.addListener(onLandscapeMatchMedia)

  onDesktopMatchMedia(desktopMatchMedia)
  onMobileMatchMedia(mobileMatchMedia)
  onHoverMatchMedia(hoverMatchMedia)
  onPortraitMatchMedia(portraitMatchMedia)
  onLandscapeMatchMedia(landscapeMatchMedia)

  function onDesktopMatchMedia(event) {
    if (isDesktop.value !== event.matches) {
      mainStore.setIsDesktop(event.matches)
    }
  }

  function onMobileMatchMedia(event) {
    if (isMobile.value !== event.matches) {
      mainStore.setIsMobile(event.matches)
    }
  }

  function onHoverMatchMedia(event) {
    if (isHoverSupported.value !== event.matches) {
      mainStore.setIsHoverSupported(event.matches)
    }
  }

  function onPortraitMatchMedia(event) {
    if (isPortrait.value !== event.matches) {
      mainStore.setIsPortrait(event.matches)
    }
  }

  function onLandscapeMatchMedia(event) {
    if (isLandscape.value !== event.matches) {
      mainStore.setIsLandscape(event.matches)
    }
  }
})
