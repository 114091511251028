import validate from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/validate.js";
import is_45authenticated_45global from "/usr/local/src/party-pictures-fe/middleware/is-authenticated.global.js";
import redirect_45account_45index_45page_45global from "/usr/local/src/party-pictures-fe/middleware/redirect-account-index-page.global.js";
import manifest_45route_45rule from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  is_45authenticated_45global,
  redirect_45account_45index_45page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}