<template>
  <div
    class="app-spinner"
    :style="{
      '--app-spinner-color': color,
      '--app-spinner-dimension': dimension
    }"
  >
    <div class="app-spinner__content">
      <div class="app-spinner__circle" />
      <p
        v-if="message"
        class="app-spinner__message"
        v-html="message"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        default: null,
      },
      color: {
        type: String,
        default: 'var(--color-primary)',
      },
      dimension: {
        type: String,
        default: '100px',
      },
    },
  }
</script>

<style lang="scss">
  .app-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-spinner__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app-spinner__circle,
  .app-spinner__circle::after {
    border-radius: 50%;
  }

  .app-spinner__circle {
    position: relative;
    width: var(--app-spinner-dimension);
    height: var(--app-spinner-dimension);
  }

  .app-spinner__circle::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(40deg);
    animation: spin 1.3s cubic-bezier(.42, 0, 0, 1.32) infinite;
    border: 2px solid transparent;
    border-left-color: var(--app-spinner-color);
  }

  .app-spinner__message {
    margin-top: 16px;
  }

  @keyframes spin {
    0% {
      transform: rotate(40deg);
    }

    100% {
      transform: rotate(400deg);
    }
  }
</style>
