/**
 * @memberOf momentshare.models.subscription
 * @constructor
 */
export class SubscriptionOption {
  constructor({
    id,
    cost,
    description,
    tax,
    taxPercentage,
  }) {
    this.id = id
    this.cost = cost
    this.description = description
    this.tax = tax
    this.taxPercentage = taxPercentage
  }
}
