import { defineStore } from 'pinia'

import actions from './actions'

export const useGuestStore = defineStore('guestStore', {
  state: () => ({
    tokens: {},
  }),
  actions,
  getters: {
    /**
     * @param state
     * @returns {string}
     */
    getGuestEventToken: (state) => (eventId) => {
      return state.tokens[eventId]
    },
  },
})
