export const CONNECTOR = {
  GOOGLE_DRIVE: 'Google Drive',
  DROPBOX: 'Dropbox',
  MOMENTSHARE_DRIVE: 'Premium',
}

export const CONNECTOR_ENDPOINT = {
  [CONNECTOR.GOOGLE_DRIVE]: 'google',
  [CONNECTOR.DROPBOX]: 'dropbox',
}
