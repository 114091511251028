/**
 * @typedef {InteractionsService}
 * @alias this.$interactionsService
 */
export class InteractionsService {
  mediaLikesCookie = 'ms_event_%EVENT%_likes'
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
    this.$clientCookieService = this.nuxtApp.$clientCookieService
    this.$eventService = this.nuxtApp.$eventService
  }

  getCookieName(eventId) {
    return this.mediaLikesCookie.replace('%EVENT%', eventId)
  }

  /**
   * @param {string} eventId
   * @param {string} imageId
   * @param {number} likes //Only -1 or 1 allowed
   * @returns {Promise<void>}
   */
  async setMediaLikes(eventId, imageId, likes) {
    this.setMediaLikesInCookie(eventId, imageId, likes)
    const authHeaders = await this.$eventService.getGuestEventAuthHeaders(eventId)

    return this.$apiService.instance.post(
      `/interactions/likes/${eventId}`,
      { imageId, likes },
      {
        headers: {
          ...(authHeaders && { ...authHeaders }),
        },
      },
    )
  }

  /**
   * @param {string} eventId
   * @returns {Object}
   */
  getCurrentEntriesInMediaLikesCookie(eventId) {
    const cookieName = this.getCookieName(eventId)
    let currentEntries = {}

    try {
      currentEntries = JSON.parse(this.$clientCookieService.get(cookieName))
    } catch {
      currentEntries = {}
    }

    return currentEntries
  }

  /**
   * @param {string} eventId
   * @param {string} imageId
   * @param {number} likes //Only -1 or 1 allowed
   * @returns {void}
   */
  setMediaLikesInCookie(eventId, imageId, likes) {
    const cookieName = this.getCookieName(eventId)
    const currentEntries = this.getCurrentEntriesInMediaLikesCookie(eventId)

    if (likes === 1) {
      currentEntries[imageId] = true
    } else {
      delete currentEntries[imageId]
    }

    this.$clientCookieService.set(cookieName, JSON.stringify(currentEntries))
  }

  /**
   * @param {string} eventId
   * @param {string} imageId
   * @returns {boolean}
   */
  getMediaIsLiked(eventId, imageId) {
    const currentEntries = this.getCurrentEntriesInMediaLikesCookie(eventId)

    return currentEntries[imageId] ?? false
  }
}
