import { Base } from '~/lib/models/base/base'

/**
 * @memberOf momentshare.models.user
 * @constructor
 */
export class UserAddress extends Base {
  constructor({
    id,
    name,
    address,
    postalCode,
    city,
    country,
  }) {
    super()

    this.id = id
    this.name = name
    this.address = address
    this.postalCode = postalCode
    this.city = city
    this.country = country
  }
}
