import { defineStore } from 'pinia'

import actions from './actions'
import getters from './getters'

export const useOverlayStore = defineStore('overlay', {
  state: () => ({
    settings: null,
    mobile: null,
    desktop: null,
    activeAppModal: null,
    activeAppModalTransition: null,
    activeAppModalOptions: null,
    activeAppTray: null,
    activeAppTrayTransition: null,
    activeAppTrayOptions: null,
    activeFlyIn: null,
    activeFlyInTransition: null,
    activeFlyInOptions: null,

    eventImagesModalIsActive: false,
  }),
  actions,
  getters,
})
