export default {
  /**
   * @param {boolean} isDesktop
   * @returns {void}
   */
  setIsDesktop(isDesktop) {
    this.$state.isDesktop = isDesktop
  },

  /**
   * @param {boolean} isMobile
   * @returns {void}
   */
  setIsMobile(isMobile) {
    this.$state.isMobile = isMobile
  },

  /**
   * @param {boolean} isHoverSupported
   * @returns {void}
   */
  setIsHoverSupported(isHoverSupported) {
    this.$state.isHoverSupported = isHoverSupported
  },

  /**
   * @param {boolean} isPortrait
   * @returns {void}
   */
  setIsPortrait(isPortrait) {
    this.$state.isPortrait = isPortrait
  },

  /**
   * @param {boolean} isLandscape
   * @returns {void}
   */
  setIsLandscape(isLandscape) {
    this.$state.isLandscape = isLandscape
  },

  setIsMobileDevice(isMobileDevice) {
    this.$state.isMobileDevice = isMobileDevice
  },
}
