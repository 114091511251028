import { User } from '~/lib/models/user/user'
import { UserAddress } from '~/lib/models/user/user-address'

/**
 * @param {Object} user
 * @returns {momentshare.models.user.User}
 */
export function mapUser(user) {
  return new User({
    id: user.id,
    username: user.username,
    displayName: user.displayName,
    googleDriveConnected: user.googleDriveConnected,
    dropboxConnected: user.dropboxConnected,
    address: user.address ? mapUserAddress(user.address) : null,
    isAdmin: user.role === 1,
  })
}

/**
 * @param {Object} address
 * @returns {momentshare.models.user.UserAddress}
 */
export function mapUserAddress(address) {
  return new UserAddress({
    id: address.id,
    name: address.name,
    postalCode: address.postalCode,
    address: address.address,
    city: address.city,
    country: address.country,
  })
}
