import { USER_AUTH_COOKIE_KEY } from '~/lib/constants/cookies'
import { useUserStore } from '~/stores/user'

export default defineNuxtPlugin(nuxtApp => {
  const userStore = useUserStore()

  try {
    const tokenDetails = nuxtApp.$clientCookieService.get(USER_AUTH_COOKIE_KEY)

    if (!tokenDetails) {
      return
    }

    return nuxtApp.$userService
      .fetchUser()
      .then(() => nuxtApp.$eventService.fetchUserEvents())
      .catch(() => {
        userStore.setUser(null)
        nuxtApp.$clientCookieService.delete(USER_AUTH_COOKIE_KEY)
      })
  } finally {
    userStore.setIsInitialized(true)
  }
})
