import { default as indexzs4BF2BLwQMeta } from "/usr/local/src/party-pictures-fe/pages/index.vue?macro=true";
import { default as indexCYlFPRQLQHMeta } from "/usr/local/src/party-pictures-fe/pages/about/index.vue?macro=true";
import { default as index3PpWHFMZhPMeta } from "/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue?macro=true";
import { default as indexwR9qahYgI1Meta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue?macro=true";
import { default as indexY1SmOE0YxrMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue?macro=true";
import { default as indexara1LA3gCeMeta } from "/usr/local/src/party-pictures-fe/pages/account/events/index.vue?macro=true";
import { default as orderJVuXo0azc4Meta } from "/usr/local/src/party-pictures-fe/pages/account/events/order.vue?macro=true";
import { default as eventsVdnzkdqxcPMeta } from "/usr/local/src/party-pictures-fe/pages/account/events.vue?macro=true";
import { default as indexGXPakvz61NMeta } from "/usr/local/src/party-pictures-fe/pages/account/help/index.vue?macro=true";
import { default as indexoZzpXD5vjsMeta } from "/usr/local/src/party-pictures-fe/pages/account/index.vue?macro=true";
import { default as indexvWV6QpqrgeMeta } from "/usr/local/src/party-pictures-fe/pages/account/orders/index.vue?macro=true";
import { default as indexlBtCkxsSoKMeta } from "/usr/local/src/party-pictures-fe/pages/account/user/index.vue?macro=true";
import { default as indexpxLgaj7EPxMeta } from "/usr/local/src/party-pictures-fe/pages/admin/index.vue?macro=true";
import { default as _91event_931ZY591QiaMMeta } from "/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue?macro=true";
import { default as indexCnih7ivg3KMeta } from "/usr/local/src/party-pictures-fe/pages/event/new/index.vue?macro=true";
import { default as indexBmCRcYT293Meta } from "/usr/local/src/party-pictures-fe/pages/help-faq/index.vue?macro=true";
import { default as indexqUdAqXXSgiMeta } from "/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue?macro=true";
import { default as indexskK9UQZug8Meta } from "/usr/local/src/party-pictures-fe/pages/login/index.vue?macro=true";
import { default as indexQG4Orf9e0xMeta } from "/usr/local/src/party-pictures-fe/pages/prices/index.vue?macro=true";
import { default as index2xn16TASjZMeta } from "/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue?macro=true";
import { default as indexPBwaC8dJgTMeta } from "/usr/local/src/party-pictures-fe/pages/register/index.vue?macro=true";
import { default as indexiPX292p0ygMeta } from "/usr/local/src/party-pictures-fe/pages/reset-password/index.vue?macro=true";
import { default as indexxyGRys7uJPMeta } from "/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue?macro=true";
import { default as component_45stubkgZDd53JZpMeta } from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubkgZDd53JZp } from "/usr/local/src/party-pictures-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "about___nl",
    path: "/nl/about",
    component: () => import("/usr/local/src/party-pictures-fe/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "account-connectors___nl",
    path: "/nl/account/connectors",
    meta: index3PpWHFMZhPMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/connectors/index.vue").then(m => m.default || m)
  },
  {
    name: eventsVdnzkdqxcPMeta?.name,
    path: "/nl/account/events",
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events.vue").then(m => m.default || m),
    children: [
  {
    name: "account-events-event___nl",
    path: ":event()",
    meta: indexwR9qahYgI1Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-events-event-properties___nl",
    path: ":event()/properties",
    meta: indexY1SmOE0YxrMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/[event]/properties/index.vue").then(m => m.default || m)
  },
  {
    name: "account-events___nl",
    path: "",
    meta: indexara1LA3gCeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/index.vue").then(m => m.default || m)
  },
  {
    name: "account-events-order___nl",
    path: "order",
    meta: orderJVuXo0azc4Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/events/order.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "account-help___nl",
    path: "/nl/account/help",
    meta: indexGXPakvz61NMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/help/index.vue").then(m => m.default || m)
  },
  {
    name: "account___nl",
    path: "/nl/account",
    meta: indexoZzpXD5vjsMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___nl",
    path: "/nl/account/orders",
    meta: indexvWV6QpqrgeMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-user___nl",
    path: "/nl/account/user",
    meta: indexlBtCkxsSoKMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/account/user/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___nl",
    path: "/nl/admin",
    meta: indexpxLgaj7EPxMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "event-name-event___nl",
    path: "/nl/event/:name()/:event()",
    meta: _91event_931ZY591QiaMMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/[name]/[event].vue").then(m => m.default || m)
  },
  {
    name: "event-new___nl",
    path: "/nl/event/new",
    meta: indexCnih7ivg3KMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/event/new/index.vue").then(m => m.default || m)
  },
  {
    name: "help-faq___nl",
    path: "/nl/help-faq",
    component: () => import("/usr/local/src/party-pictures-fe/pages/help-faq/index.vue").then(m => m.default || m)
  },
  {
    name: "how-it-works___nl",
    path: "/nl/how-it-works",
    component: () => import("/usr/local/src/party-pictures-fe/pages/how-it-works/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/usr/local/src/party-pictures-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___nl",
    path: "/nl/login",
    meta: indexskK9UQZug8Meta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "prices___nl",
    path: "/nl/prices",
    component: () => import("/usr/local/src/party-pictures-fe/pages/prices/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___nl",
    path: "/nl/privacy-policy",
    component: () => import("/usr/local/src/party-pictures-fe/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "register___nl",
    path: "/nl/register",
    meta: indexPBwaC8dJgTMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___nl",
    path: "/nl/reset-password",
    meta: indexiPX292p0ygMeta || {},
    component: () => import("/usr/local/src/party-pictures-fe/pages/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/terms-and-conditions",
    component: () => import("/usr/local/src/party-pictures-fe/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubkgZDd53JZpMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubkgZDd53JZp
  }
]